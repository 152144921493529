import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-no-access',
  standalone: true,
  imports: [MatButtonModule, MatCardModule],
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent {
  constructor(private location: Location) { }

  goBack(): void {
    this.location.back();
  }
}
