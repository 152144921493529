<div class="flex flex-col basis-full">

  <div class="topbar-container flex flex-row items-center">
    <app-logo width="127" height="31"></app-logo>
    @if (isMobile) {
      <button mat-icon-button (click)="toggleSidneNav()">
        <mat-icon>menu_open</mat-icon>
      </button>
    }
  </div>

  <app-collapsable-menu [navItems]="navItems" (clicked)="toggleSidneNav()"></app-collapsable-menu>

</div>