import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-nav-bar',
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {

  @Input() responsive: boolean;
  @Input() currentUser: string;
  @Output() toggleSideMenu = new EventEmitter<boolean>();
  @Output() logout = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  doToggleSideMenu() {
    this.toggleSideMenu.emit(true);
  }

  doLogout() {
    this.logout.emit('admin');
  }

}
