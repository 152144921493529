<nav class="flex flex-row items-center">
  <button mat-icon-button (click)="doToggleSideMenu()" *ngIf="responsive">
    <mat-icon color="primary">menu</mat-icon>
  </button>

  <div class="basis-full"></div>

  <div><strong>{{currentUser}}</strong></div>
  <a (click)="doLogout()" mat-icon-button  m-l-8 class="logout ml-2" color="primary">
    <mat-icon class="material-icons-outlined">logout</mat-icon>
  </a>
</nav>