import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { awsConfig } from '../../../../../../../../libs/includes/aws-export';

@Injectable({
  providedIn: 'root'
})
export class TokenLoginGuard {
  constructor(
    private authService: AuthService,
    private appState: AppStateService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // make sure we are in logged out state.
    // this.appState.exitMode(ViewModes.LOGGED_OUT);

    if (state.url.search(/^\/auth\/.*/) !== -1) {
      return true;
    }
    const { token } = route.queryParams;

    if (token) {
      // we need to be logged out to verifyToken (iam auth only)
      // maybe move this stuff into AuthService?
      return this.authService.programmaticLogout().pipe(
        switchMap(val => this.authService.verifyToken({ token })
          .pipe(
            switchMap(result => {
              if (result.payload) {
                const payload = JSON.parse(result.payload) as { username: string, token: string, target: string };
                return this.authService.customChallengeLogin({ challenge: payload.token, username: payload.username, removeChallenge: true })
                  .pipe(
                    map(result => {
                      if (result.status === 'SUCCESS') {
                        if (payload.target === 'FACILITY_ADMIN_ONBOARDING') {
                          return this.router.parseUrl(`/auth/onboarding?token=${token}`);
                        }
                      }
                      return this.router.parseUrl('/auth/expired');
                    })
                  )
              }
              return of(this.router.parseUrl('/auth/expired'))
            })
          )
        ))
    } else {
      return this.router.parseUrl('/auth/login');
    }
  }
}