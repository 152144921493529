const getRetryCount = (): number => {
  return parseInt(localStorage.getItem('retryCount') || '0', 10);
}

const setRetryCount = (count: string | number): void => {
  localStorage.setItem('retryCount', count.toString());
}

const getExponentialDelay = (retryCount: number, baseDelay: number): number => {
  return baseDelay * Math.pow(2, retryCount);
}

export function retryWithLocalStorageExponentialBackoff(retryFn: Function, failureFn: Function, maxRetries = 5, delay = 2000): void {
  const retryCount = getRetryCount();

  if (retryCount >= maxRetries) {
    setRetryCount(0);
    console.error("Max retries reached, not retrying anymore.");
    return failureFn();
  }

  setTimeout(() => {
    setRetryCount(retryCount + 1);
    retryFn();
  }, getExponentialDelay(retryCount, delay));
}
