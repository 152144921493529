<div class="flex flex-col basis-full bg-neutral-100">

  <div class="flex flex-row items-center place-content-between elevation-3 h-[75px] px-4">
    <app-logo width="127" height="31" class="text-primary-500"></app-logo>
    @if (isMobile) {
      <button mat-icon-button color="primary" (click)="toggleSidneNav()">
        <mat-icon>menu_open</mat-icon>
      </button>
    }
  </div>

  <app-collapsable-menu [navItems]="navItems" (clicked)="toggleSidneNav()" class="text-primary-500 mt-4"></app-collapsable-menu>

</div>