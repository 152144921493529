import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { APOLLO_USER_GROUPS_KEYS } from '../../models/global';

export interface NavItem {
  path: string;
  title: string;
  icon?: string;
  svgIcon?: string;
  class?: string;
  isExternalLink: boolean;
  subMenu? : NavItem[];
  expand?: boolean;
  groups?: APOLLO_USER_GROUPS_KEYS[];
}


@Component({
  selector: 'app-collapsable-menu',
  standalone: true,
  imports: [NgFor, MatIconModule, RouterLink, NgIf, NgClass],
  templateUrl: './collapsable-menu.component.html',
  styleUrls: ['./collapsable-menu.component.scss']
})
export class CollapsableMenuComponent {
  @Input() navItems: NavItem[];
  @Output() clicked = new EventEmitter<boolean>()
  expand = true;
  constructor() { }

  toggleSidebar() {
    this.clicked.emit(true);
  }

}
