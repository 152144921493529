import { ErrorHandler, importProvidersFrom } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { awsConfig } from '../../../libs/includes/aws-export';

import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from './app/app.component';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './app/app.routing';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideHttpClient } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule} from '@angular/material-moment-adapter';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { AppErrorHandler } from './app/app.error-handler';
import { MatDialogModule } from '@angular/material/dialog';
import { fetchAuthSession } from 'aws-amplify/auth';

Amplify.configure(awsConfig.adminApp, {
  API: {
   GraphQL: {
     headers: async () => {
       const currentSession = await fetchAuthSession();
       if (currentSession.tokens) {
         const idToken = currentSession.tokens.idToken?.toString();
         return { Authorization: idToken };
       } else {
         return undefined
       }
     }
   }
  }
});

export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const providers =  [
  importProvidersFrom(RouterModule.forRoot(APP_ROUTES, {
    scrollPositionRestoration: 'top',
  })),
  provideAnimations(),
  // DatePipe,
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { hideRequiredMarker: true } },
  provideHttpClient(),
  importProvidersFrom(MatMomentDateModule),
  { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
  importProvidersFrom(FileUploadModule),
  { provide: ErrorHandler, useClass: AppErrorHandler },
  importProvidersFrom(MatDialogModule),

]

// const enableGtm = environment.local ? !['localhost', environment.local.domain].includes(window.location.hostname) : true;
// if (enableGtm) {
//   providers.push(importProvidersFrom(GoogleTagManagerModule.forRoot({ id: environment.gtm })));
// }

bootstrapApplication(AppComponent, {
  providers,
}).catch(error => {
  showErrorLog(error);
})

function showErrorLog(error: any) {
  document.getElementsByTagName('body')[0].innerHTML = `
      <div style="padding:24px">
        <h1>Resparke failed to load because of the following error:</h1>
        <h3>"${error.message}"</h3>
        <pre style="font-weight: 300; text-wrap: inherit; background-color: #E0F3F3; padding: 16px;">${error.stack.toString()}
        </pre>
        <p style="padding: 16px; background-color: #E4C5DC;">If you get this error, please take a picture or screenshot and report it at https://www.resparke.com/support/</p>
      </div>
      `
}