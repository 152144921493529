
<mat-sidenav-container *ngIf="isResponsive$ | async as isResponsive"  (backdropClick)="sidenav.close()">
  <mat-sidenav #sidenav [opened]="layout.opened" [mode]="layout.mode" disableClose>
    <app-side-nav
      class="flex flex-col basis-full"
      (toggleSideNav)="isResponsive.responsive && sidenav.toggle()"
      [isMobile]="isResponsive.responsive"
      ></app-side-nav>
  </mat-sidenav>
  <mat-sidenav-content class="flex flex-col basis-full place-content-start"  [ngClass]="{'is-responsive': isResponsive.responsive}">
    <app-nav-bar
    class="mat-elevation-z3"
      [responsive]="isResponsive.responsive"
      (toggleSideMenu)="sidenav.toggle()"
      [currentUser]="currentUser"
      (logout)="doLogout($event)"
      ></app-nav-bar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


