<ul class="collapsable-menu" *ngFor="let menuItem of navItems">

  <li class="menu-group">
    <div class="menu-toggle flex flex-row items-center cursor-pointer" (click)="menuItem.expand = !menuItem.expand">
      <div class="flex">
        <mat-icon class="material-icons-outlined">{{ menuItem.icon }}</mat-icon>
      </div>
      <span class="flex basis-full">{{ menuItem.title }}</span>
      <span class="material-icons-outlined expander" *ngIf="menuItem.expand">
        expand_more
      </span>
      <span class="material-icons-outlined expander" *ngIf="!menuItem.expand">
        expand_less
      </span>
    </div>

    <ul class="menu-items" [ngClass]="{ expand: menuItem.expand, contract: !menuItem.expand }">
      <li *ngFor="let subMenuItem of menuItem.subMenu">
        <a [routerLink]="subMenuItem.path" class="flex flex-row items-center" (click)="toggleSidebar()">
          <div class="flex">
            <mat-icon class="material-icons-outlined">
              {{ subMenuItem.icon}}
            </mat-icon>
          </div>
          <span class="flex basis-full">{{ subMenuItem.title }}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>