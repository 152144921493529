import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { SlackService } from './shared/services/slack/slack.service';
import { AuthService } from './modules/auth/services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { retryWithLocalStorageExponentialBackoff } from './shared/utils/retry';
import { AppStateService } from './shared/services/app-state/app-state.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private ngZone: NgZone,
  ) {
  }

  handleError(error: Error) {

    // Amplify Auth can throw a string error. This may change in v6 if Amplify
    if (typeof error === 'string') {
      error = {
        name: error,
        message: error,
        stack: 'unavailable',
      }
    }
    console.log(error);

    if (this.errorIsReloadable(error)) {
      return retryWithLocalStorageExponentialBackoff(
        () => window.location.reload(),
        () => this.redirectToErrorPage()
      )
    }

    if (this.errorIs404(error)) {
      const router = this.injector.get(Router);

      return router.navigate(['/no-access']);
    }

    if (this.enableSlackMessaging()) {
      this.sendErrorToSlack(error).subscribe(() => {
        this.redirectToErrorPage()
      });
    }
  }

  errorIsReloadable(error: Error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      return true
    }

    if (error.message.includes('Failed to fetch dynamically imported module')) {
      return true
    }
    if (error.message.includes('Network Error')) {
      return true
    }

    if (error.message.includes('An unknown error has occurred.')) {
      return true;
    }

    return false
  }

  errorIs404(error: Error) {
    return error.message.includes('Request failed with status code 404');
  }

  sendErrorToSlack(error: Error) {
    const slack = this.injector.get(SlackService);
    const appState = this.injector.get(AppStateService);
    const authService = this.injector.get(AuthService);
    const currentRoute = appState.get<string[]>('currentRoute');
    const intendedRoute = Array.isArray(currentRoute) ? currentRoute.join('/') : 'n/a';
    const environmentName = environment.name;
    const message = `[Resparke App: ${authService.authState?.username || 'not logged in'}] \`\`\`${error.message}${error.stack}\`\`\`\n\`\`\`${JSON.stringify(window.location)}\`\`\`\n\'${window.navigator ? window.navigator.userAgent : ''}\` intended route: \`${intendedRoute}\``;
    return slack.sendMessage({
      message,
      channel: `breaks-${environmentName}`
    });
  }

  enableSlackMessaging() {
    return environment.local ? !['localhost', environment.local.domain].includes(window.location.hostname) : true
  }

  redirectToErrorPage() {
    const router = this.injector.get(Router);

    this.ngZone.run(() => router.navigate(['/error']));
  }
}