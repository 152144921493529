import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterOutlet } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { AppStateService } from '../../services/app-state/app-state.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { NavBarComponent } from '../nav-bar/nav-bar.component';

export interface Layout {
  openSideNav: boolean;
  opened: boolean;
  mode: MatDrawerMode;
  fixedViewPort: boolean;
  fixedTopGap: number;
}

const defaultLayout: Layout = {
  openSideNav: true,
  opened: true,
  mode: 'side',
  fixedViewPort: true,
  fixedTopGap: 64,
};


@Component({
  selector: 'app-authenticated-layout',
  imports: [RouterOutlet, AsyncPipe, MatSidenavModule, SideNavComponent, NavBarComponent],
  templateUrl: './authenticated-layout.component.html',
  styleUrls: ['./authenticated-layout.component.scss']
})
export class AuthenticatedLayoutComponent {

  currentUser = this.authService.authState.username;
  layout: Layout = defaultLayout;
  isResponsive$ = this.layoutService.isResponsive$;
  onDestroy = new Subject();

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
    private router: Router,
    private appState: AppStateService,
  ) {



    this.layoutService.breakpoint$
      .pipe(
        takeUntil(this.onDestroy),
        tap(deviceMap => {
          if (deviceMap['is-xSmall'] || deviceMap['is-small']) {
            this.layout = {
              ...this.layout,
              mode: 'over',
              opened: false
            };
          } else {
            this.layout = {
              ...this.layout,
              mode: 'side',
              opened: true
            }
          }
        })
      ).subscribe();
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

  doLogout(mode: string) {
    // this.router.navigate(['/auth/logout']);
    this.authService.logout().subscribe(val => {
      this.router.navigate(['/auth/logout/securely'])
    })
  }
}

