<div>
  <h1 class="text-center">Resparke cannot be accessed</h1>
</div>
<p class="text-center">Resparke is being blocked by your organisation's firewall.</p>
<p class="text-center">
  To access this content, please contact your IT team and ask them to add
  Resparke to your safelist.
</p>
<p class="text-lg text-center mt-16">
  Domain to safelist: (*.resparke.com)
</p>

<div class="text-center mt-12">
  <button color="primary" mat-flat-button (click)="goBack()">
    Go Back
  </button>
</div>